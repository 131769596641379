import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RoleClaim from './components/RoleClaim';
import React, { useEffect, useState } from 'react';
import { 
  ConnectWallet, 
  Web3Button,
  useContract,
  useContractRead,
  useAddress,
  useTokenBalance,
  ThirdwebNftMedia,
  darkTheme
} from "@thirdweb-dev/react";
import NFTCard from "./components/NFTCard";
import "./styles/Home.css";
import { BigNumber, ethers } from "ethers";

interface NftBalance {
  tokenId: string;
  metadata: {
    id: string;
    uri: string;
    name?: string;
    description?: string;
    image?: string;
    [key: string]: any;
  };
  tokenUri: string;
}

interface PrizeEvent {
  prizeType: number;
  tokenAddress: string;
  amount: string;
  quantity: string;
}

interface PrizeOutcome {
  name: string;
  probability: number;
  prizeType: number;
  tokenAddress: string;
  amount: string | number;
  tokenId: number;
  quantity: number;
  percentage: number;
}

type PrizeGivenEvent = {
  player: string;
  pType: number;
  token: string;
  amt: string;
  tid: number;
  qty: number;
  idx: number;
};

// Add this type definition for contract events
type ContractEvent = {
  eventName: string;
  data: PrizeGivenEvent;
};

// Add these type definitions at the top of your file
interface Log {
  topics: string[];
  data: string;
  transactionHash: string;
  logIndex: number;
  blockNumber: number;
  blockHash: string;
  address: string;
}

interface TransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  gasUsed: ethers.BigNumber;
  logsBloom: string;
  blockHash: string;
  transactionHash: string;
  logs: Log[];
  blockNumber: number;
  confirmations: number;
  cumulativeGasUsed: ethers.BigNumber;
  effectiveGasPrice: ethers.BigNumber;
  status: number;
  type: number;
  byzantium: boolean;
}

// Add this type for prize names to ensure consistency
type PrizeName = 
  | "Greed's Gift"
  | "Maw's Curse"
  | "Spoils of The Core"
  | "Sakura's Embrace"
  | "Siren's Call"
  | "Wrath's Intervention"
  | "Heretic's Honour";

// Add this at the top of your file with other constants
const AURA_TOKEN = "0x12b9e928394bc1bb98e18c9d51046b3f5393d4c0";
const HERESY_TOKEN = "0x432d38F83a50EC77C409D086e97448794cf76dCF";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/roleclaim" element={<RoleClaim />} />
      </Routes>
    </Router>
  );
};

const Home: React.FC = () => {
  const address = useAddress();
  const [ownedTokens, setOwnedTokens] = useState<NftBalance[]>([]);
  const [stakedTokens, setStakedTokens] = useState<BigNumber[]>([]);
  const [claimableRewards, setClaimableRewards] = useState<BigNumber>();
  const [isLoading, setIsLoading] = useState(false);
  const [auraAllowance, setAuraAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentPrize, setCurrentPrize] = useState<string | null>(null);
  const [avaxBalance, setAvaxBalance] = useState<string>("0");
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [blockConfirmations, setBlockConfirmations] = useState<number>(0);
  const [explorationBlock, setExplorationBlock] = useState<number | null>(null);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [explorationState, setExplorationState] = useState<'none' | 'exploring' | 'ready-to-claim'>('none');
  const [currentPrizeName, setCurrentPrizeName] = useState<string | null>(null);
  const [needsApproval, setNeedsApproval] = useState<boolean>(true);
  const [userDisplayName, setUserDisplayName] = useState<string | null>(null);
  const [circulatingSupply, setCirculatingSupply] = useState<string>("Loading...");
  const [burnedTokens, setBurnedTokens] = useState<string>("Loading...");
  const [emergencyClaimAvailable, setEmergencyClaimAvailable] = useState(false);

  // Updated contract addresses for mainnet
  const distortionsContractAddress = "0x0a337be2ea71e3aea9c82d45b036ac6a6123b6d0";
  const stakingContractAddress = "0x51697170F78136c8d143B0013Cf5B229aDe70757";
  const prizeDistributionContractAddress = "0xd3106799fD6D4bA51AD58A4e77a68fC10f47FF5c";
  const auraContractAddress = "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399";
  const heresyContractAddress = "0x432d38f83a50ec77c409d086e97448794cf76dcf";

  // Initialize contracts with useContract hooks
  const { contract: distortionsContract, isLoading: isDistortionsLoading } = useContract(
    distortionsContractAddress,
    "nft-drop"
  );
  const { contract: stakingContract, isLoading: isStakingLoading } = useContract(
    stakingContractAddress,
    "custom" // Add the correct contract type here if known
  );
  const { contract: prizeDistributionContract, isLoading: isPrizeLoading } = useContract(
    prizeDistributionContractAddress,
    "custom"
  );
  const { contract: auraContract, isLoading: isAuraLoading } = useContract(
    auraContractAddress,
    "token"
  );
  const { contract: heresyContract, isLoading: isHeresyLoading } = useContract(
    heresyContractAddress,
    "token"
  );

  // Initialize role claim contract
  const { contract: roleClaimContract } = useContract(
    "0x5463143F0E79DBaA602AebA78d6c31A433d6F96F",
    "custom"
  );

  // Add useEffect to fetch display name
  useEffect(() => {
    const fetchDisplayName = async () => {
      if (!address || !roleClaimContract) return;
      
      try {
        const name = await roleClaimContract.call("readDisplayName", [address]);
        if (name && name !== "") {
          setUserDisplayName(name);
        }
      } catch (error) {
        console.error("Error fetching display name:", error);
      }
    };

    fetchDisplayName();
  }, [address, roleClaimContract]);

  // Add a new useEffect to monitor contract initialization
  useEffect(() => {
    console.log("Contract initialization status:", {
      distortions: { contract: !!distortionsContract, loading: isDistortionsLoading },
      staking: { contract: !!stakingContract, loading: isStakingLoading },
      prizeDistribution: { contract: !!prizeDistributionContract, loading: isPrizeLoading },
      aura: { contract: !!auraContract, loading: isAuraLoading },
      heresy: { contract: !!heresyContract, loading: isHeresyLoading }
    });

    // Only proceed with data refresh when all contracts are ready
    if (address && 
        distortionsContract && 
        stakingContract && 
        prizeDistributionContract && 
        auraContract && heresyContract &&
        !isDistortionsLoading &&
        !isStakingLoading &&
        !isPrizeLoading &&
        !isAuraLoading &&
        !isHeresyLoading) {
      console.log("All contracts initialized, refreshing data...");
      refreshAllData();
    }
  }, [
    address,
    distortionsContract,
    stakingContract,
    prizeDistributionContract,
    auraContract,
    heresyContract,
    isDistortionsLoading,
    isStakingLoading,
    isPrizeLoading,
    isAuraLoading,
    isHeresyLoading
  ]);

  // Modify the existing address useEffect to only handle initial connection
  useEffect(() => {
    if (address) {
      console.log("Wallet connected:", address);
    }
  }, [address]);

  const isWalletConnected = !!address;
  const hasTokenStaked = stakedTokens.length > 0;
  const { data: auraBalance } = useTokenBalance(auraContract, address);
  const [auraFee, setAuraFee] = useState<BigNumber>();
  const [avaxFee, setAvaxFee] = useState<BigNumber>();
  const [hasExplored, setHasExplored] = useState(false);
  const { data: heresyBalance } = useTokenBalance(heresyContract, address);

  // Add prize outcomes constant
  const PRIZE_OUTCOMES: PrizeOutcome[] = [
    {
        "name": "Greed's Gift",
        "probability": 500,
        "prizeType": 1,
        "tokenAddress": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
        "amount": "250000000000000000",
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Maw's Curse",
        "probability": 3000,
        "prizeType": 0,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Spoils of The Core",
        "probability": 1250,
        "prizeType": 4,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 1,
        "percentage": 0
    },
    {
      "name": "Spoils of The Core",
      "probability": 1250,
      "prizeType": 5,
      "tokenAddress": "0x0000000000000000000000000000000000000000",
      "amount": 0,
      "tokenId": 0,
      "quantity": 1,
      "percentage": 0
  },
    {
        "name": "Sakura's Embrace",
        "probability": 500,
        "prizeType": 4,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 2,
        "percentage": 0
    },
    {
        "name": "Siren's Call",
        "probability": 1500,
        "prizeType": 1,
        "tokenAddress": "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399",
        "amount": "500000000000000000000",
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Wrath's Intervention",
        "probability": 1000,
        "prizeType": 1,
        "tokenAddress": "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399",
        "amount": "750000000000000000000",
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Heretic's Honour",
        "probability": 1000,
        "prizeType": 6,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 0,
        "percentage": 500
    }
];

  // Add new function to match prize event to outcome
  const matchPrizeToOutcome = (event: PrizeGivenEvent): string | null => {
    console.log("Trying to match prize event:", event);
    
    const matchingPrize = PRIZE_OUTCOMES.find(outcome => {
      console.log("Checking against outcome:", outcome);
      console.log("Type match:", outcome.prizeType === event.pType);
      console.log("Address match:", outcome.tokenAddress.toLowerCase() === event.token.toLowerCase());
      console.log("Amount/Quantity match:", 
        outcome.amount.toString() === event.amt || 
        outcome.quantity === event.qty ||
        (outcome.percentage > 0 && event.pType === 7)
      );
      
      return outcome.prizeType === event.pType &&
        outcome.tokenAddress.toLowerCase() === event.token.toLowerCase() &&
        (outcome.amount.toString() === event.amt || 
         outcome.quantity === event.qty ||
         (outcome.percentage > 0 && event.pType === 7));
    });

    console.log("Matched prize:", matchingPrize);
    return matchingPrize ? matchingPrize.name : null;
  };

  // Add a new function to handle all data refreshes
  const refreshAllData = async () => {
    if (!address) return;
    console.log("Starting refreshAllData with contracts:", {
      hasStakingContract: !!stakingContract,
      hasDistortionsContract: !!distortionsContract,
      hasPrizeDistributionContract: !!prizeDistributionContract,
      hasAuraContract: !!auraContract,
      hasHeresyContract: !!heresyContract,
      currentAddress: address
    });
    
    setIsLoading(true);
    try {
      await Promise.all([
        refreshTokenBalances(),
        loadStakedTokens(),
        loadClaimableRewards(),
        loadFees(),
        checkExplorationStatus(),
        fetchAvaxBalance(),
        calculateSupplies()
      ]);
    } catch (error) {
      console.error("Error in refreshAllData:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Modify the initial useEffect to include a small delay for wallet reconnection
  useEffect(() => {
    if (address) {
      console.log("Address changed, refreshing data...");
      // Initial load
      refreshAllData();
      
      // Add a delayed second refresh to handle wallet reconnection
      const timer = setTimeout(() => {
        refreshAllData();
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [address]);

  useEffect(() => {
    const checkAuraAllowance = async () => {
      try {
        if (!address || !auraContract || !auraFee) {
          console.log("Missing requirements for allowance check:", {
            hasAddress: !!address,
            hasContract: !!auraContract,
            hasFee: !!auraFee
          });
          return;
        }
        console.log("Checking AURA allowance...");
        const allowance = await auraContract.call("allowance", [address, prizeDistributionContractAddress]);
        console.log("AURA allowance:", allowance.toString());
        console.log("Required AURA fee:", auraFee.toString());

        const allowanceValue = Array.isArray(allowance) ? allowance[0] : allowance;
        setAuraAllowance(allowanceValue);
        // Compare the allowance with the required fee
        setNeedsApproval(allowanceValue.lt(auraFee));
      } catch (error) {
        console.error("Error fetching AURA allowance:", error);
        setAuraAllowance(BigNumber.from(0));
        setNeedsApproval(true);
      }
    };

    if (address && auraContract && auraFee) {
      checkAuraAllowance();
    }
  }, [address, auraContract, auraFee]); // Add auraFee to dependencies

  const refreshTokenBalances = async () => {
    try {
      if (!address) return;
      setIsLoading(true);
      console.log("Fetching owned tokens...");
      const response = await fetch(
        `https://glacier-api.avax.network/v1/chains/43114/addresses/${address}/balances:listErc721?pageSize=10&contractAddress=${distortionsContractAddress}`
      );
      const data = await response.json();
      console.log("Owned tokens fetched:", data);
      if (data && data.erc721TokenBalances) {
        const tokenBalances: NftBalance[] = await Promise.all(
          data.erc721TokenBalances.map(async (item: any) => {
            const tokenId = item.tokenId;
            let tokenUri = item.tokenUri;
            if (tokenUri.startsWith('ipfs://')) {
              tokenUri = tokenUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
            }
            let metadata: any = {};
            try {
              const metadataResponse = await fetch(tokenUri);
              metadata = await metadataResponse.json();
              if (metadata.image && metadata.image.startsWith('ipfs://')) {
                metadata.image = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
              }
            } catch (err) {
              console.error(`Failed to fetch metadata for token ${tokenId}:`, err);
            }
            return {
              tokenId,
              metadata: {
                ...metadata,
                id: tokenId,
                uri: tokenUri,
              },
              tokenUri: item.tokenUri,
            };
          })
        );
        setOwnedTokens(tokenBalances);
      } else {
        setOwnedTokens([]);
      }
    } catch (error) {
      console.error('Failed to fetch owned NFTs:', error);
      setOwnedTokens([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadStakedTokens = async () => {
    try {
      if (!stakingContract || !address) {
        console.log("LoadStakedTokens: Missing requirements:", {
          hasContract: !!stakingContract,
          hasAddress: !!address
        });
        return;
      }
      setIsLoading(true);
      console.log("Fetching staked tokens...");
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      console.log("Staked tokens fetched:", stakeInfo);
      if (Array.isArray(stakeInfo) && stakeInfo.length > 0) {
        setStakedTokens(stakeInfo[0]);
      } else {
        console.warn("Unexpected stakeInfo format:", stakeInfo);
        setStakedTokens([]);
      }
    } catch (error) {
      console.error("Failed to load staked tokens:", error);
      setStakedTokens([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadClaimableRewards = async () => {
    try {
      if (!stakingContract || !address) {
        console.log("LoadClaimableRewards: Missing requirements:", {
          hasContract: !!stakingContract,
          hasAddress: !!address
        });
        return;
      }
      setIsLoading(true);
      console.log("Fetching claimable rewards...");
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      console.log("Claimable rewards fetched:", stakeInfo);
      if (Array.isArray(stakeInfo) && stakeInfo.length > 1) {
        setClaimableRewards(stakeInfo[1]);
      } else {
        console.warn("Unexpected stakeInfo format:", stakeInfo);
        setClaimableRewards(undefined);
      }
    } catch (error) {
      console.error("Error loading claimable rewards:", error);
      setClaimableRewards(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const loadFees = async () => {
    try {
        if (!prizeDistributionContract) {
            console.log("Prize distribution contract not initialized");
            return;
        }
        console.log("Loading fees...");
        const auraFeeValue = await prizeDistributionContract.call("aFee");
        const avaxFeeValue = await prizeDistributionContract.call("vFee");
        console.log("Aura fee loaded:", auraFeeValue?.toString());
        console.log("Avax fee loaded:", avaxFeeValue?.toString());
        setAuraFee(auraFeeValue);
        setAvaxFee(avaxFeeValue);
    } catch (error) {
        console.error("Failed to load fees:", error);
    }
  };

  const checkExplorationStatus = async () => {
    try {
      console.log("Checking exploration status...");
      const playerData = await prizeDistributionContract?.call("playerData", [address]);
      
      if (playerData && !playerData.isZero()) {
        console.log("Player data (exploration block):", playerData.toString());
        setHasExplored(true);
        
        // Get current block number
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const currentBlock = await provider.getBlockNumber();
        const explorationBlockNumber = playerData.toNumber();
        const confirmations = Math.max(0, currentBlock - explorationBlockNumber);
        
        console.log(`Current block: ${currentBlock}, Exploration block: ${explorationBlockNumber}, Confirmations: ${confirmations}`);
        
        // Set exploration block and confirmations
        setExplorationBlock(explorationBlockNumber);
        setBlockConfirmations(confirmations);
        
        if (confirmations >= 3) {
          setExplorationState('ready-to-claim');
        } else {
          setExplorationState('exploring');
          setIsMonitoring(true);
        }
      } else {
        console.log("No active exploration, checking AURA approval...");
        setHasExplored(false);
        setExplorationState('none');
        
        // Check AURA approval
        if (auraContract && auraFee && address) {
          const currentAllowance = await auraContract.call("allowance", [address, prizeDistributionContractAddress]);
          const allowanceValue = Array.isArray(currentAllowance) ? currentAllowance[0] : currentAllowance;
          setNeedsApproval(allowanceValue.lt(auraFee));
        }
      }
    } catch (error) {
      console.error("Failed to check exploration status:", error);
    }
  };

  const handleStake = async (id: string) => {
    try {
      if (!address || !stakingContract) return;
      setIsLoading(true);
      console.log("Staking token with ID:", id);
      const isApproved = await distortionsContract?.call("isApprovedForAll", [address, stakingContractAddress]);
      if (!isApproved) {
        console.log("Setting approval for staking contract...");
        await distortionsContract?.call("setApprovalForAll", [stakingContractAddress, true]);
      }
      const transaction = await stakingContract.call("stake", [[id]]);
      if (transaction) {
        console.log("Token staked successfully, refreshing data...");
        await refreshTokenBalances();
        await loadStakedTokens();
        await loadClaimableRewards();
      }
    } catch (error) {
      console.error('Stake error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnstake = async (id: string) => {
    try {
      if (!stakingContract) return;
      setIsLoading(true);
      console.log("Unstaking token with ID:", id);
      const transaction = await stakingContract.call("withdraw", [[id]]);
      if (transaction) {
        console.log("Token unstaked successfully, refreshing data...");
        await refreshTokenBalances();
        await loadStakedTokens();
        await loadClaimableRewards();
      }
    } catch (error) {
      console.error('Unstake error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add useEffect for block monitoring
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let cleanup = false;

    const monitorBlocks = async () => {
      if (!isMonitoring || cleanup || !explorationBlock) return;

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const currentBlock = await provider.getBlockNumber();
        const confirmations = currentBlock - explorationBlock;
        
        console.log(`Current block: ${currentBlock}, Exploration block: ${explorationBlock}, Confirmations: ${confirmations}`);
        setBlockConfirmations(confirmations);
        
        if (confirmations >= 3) {
          try {
            console.log("Initiating claim...");
            const tx = await prizeDistributionContract?.call("claim");
            console.log("Claim transaction:", tx);

            const receipt = tx.receipt as TransactionReceipt;
            console.log("Transaction receipt:", receipt);

            // Look for the PrizeGiven event in the logs
            const prizeGivenLog = receipt.logs?.find((log: Log) => {
              // The event signature for PrizeGiven
              const eventSignature = "PrizeGiven(address,uint8,address,uint256,uint32,uint16,uint256)";
              const eventTopic = ethers.utils.id(eventSignature);
              return log.topics[0] === eventTopic;
            });

            if (prizeGivenLog) {
              console.log("Found PrizeGiven log:", prizeGivenLog);
              
              // Decode the event data
              const iface = new ethers.utils.Interface([
                "event PrizeGiven(address indexed player, uint8 pType, address token, uint256 amt, uint32 tid, uint16 qty, uint256 idx)"
              ]);
              
              const decodedLog = iface.parseLog(prizeGivenLog);
              console.log("Decoded log:", decodedLog);

              const eventData: PrizeGivenEvent = {
                player: decodedLog.args.player,
                pType: decodedLog.args.pType,
                token: decodedLog.args.token,
                amt: decodedLog.args.amt.toString(),
                tid: decodedLog.args.tid,
                qty: decodedLog.args.qty,
                idx: decodedLog.args.idx
              };
              
              console.log("Parsed event data:", eventData);
              
              const { videoName, displayName } = getPrizeDetails(
                eventData.pType,
                eventData.token,
                eventData.amt,
                eventData.qty
              );

              console.log("Final prize selection:", { videoName, displayName });

              if (videoName && displayName) {
                setCurrentPrizeName(displayName);
                setCurrentVideo(`/videos/${videoName}.mp4`);
                setIsPlayingVideo(true);
              }
            }

            // Reset states
            setExplorationState('none');
            setNeedsApproval(true);
            setAuraAllowance(BigNumber.from(0));
            
            await refreshAllData();
          } catch (error) {
            console.error("Automatic claim failed:", error);
            setExplorationState('ready-to-claim');
          }
          setIsLoading(false);
          setIsMonitoring(false);
          return;
        }
        
        if (!cleanup) {
          timeoutId = setTimeout(monitorBlocks, 1000);
        }
      } catch (error) {
        console.error("Error monitoring blocks:", error);
        setIsMonitoring(false);
        setIsLoading(false);
      }
    };

    if (isMonitoring) {
      monitorBlocks();
      setTimeout(() => setIsMonitoring(false), 30000);
    }

    return () => {
      cleanup = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isMonitoring, explorationBlock, prizeDistributionContract]);

  // Update handleExploreOrClaim
  const handleExploreOrClaim = async () => {
    try {
      setErrorMessage(null);
      if (!address || !prizeDistributionContract || !auraContract) {
        console.log("Missing address or contract instances.");
        return;
      }

      if (explorationState !== 'ready-to-claim') {
        console.log("Exploring with AVAX fee:", avaxFee?.toString());

        try {
          await prizeDistributionContract.call("explore", [], { 
            value: avaxFee
          });
          
          setIsLoading(true);
          setExplorationState('exploring');
          
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const currentBlock = await provider.getBlockNumber();
          setExplorationBlock(currentBlock);
          setBlockConfirmations(0);
          setIsMonitoring(true);
          
          await refreshAllData();
        } catch (error: any) {
          console.error("Exploration failed:", error);
          setIsMonitoring(false);
          setIsLoading(false);
          setExplorationState('none');
          return;
        }
      }

      // After successful exploration, set emergency claim as available
      setEmergencyClaimAvailable(true);
      
    } catch (error: any) {
      console.error("Error during explore or claim:", error);
      setIsMonitoring(false);
      setIsLoading(false);
    }
  };

  // Add new emergency claim function
  const handleEmergencyClaim = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      
      if (!prizeDistributionContract) {
        setErrorMessage("Contract not initialized");
        return;
      }

      try {
        // Create a promise that rejects after 30 seconds
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error("timeout")), 30000);
        });

        // Create the claim promise
        const claimPromise = prizeDistributionContract.call("claim");

        // Race between timeout and claim
        const tx = await Promise.race([claimPromise, timeoutPromise]);
        await tx.wait();
        
        // Reset states after successful claim
        setEmergencyClaimAvailable(false);
        await refreshAllData();
        
      } catch (txError: any) {
        console.error("Transaction error:", txError);
        
        // Handle specific error cases
        if (txError.message?.includes("Player did not explore")) {
          setErrorMessage("No prize available to claim");
        } else if (
          txError.message?.includes("timeout") || 
          txError.code === "TIMEOUT" ||
          txError.message?.includes("execution reverted")
        ) {
          setErrorMessage("Request timed out. Please try again");
        } else {
          setErrorMessage("Emergency claim failed. Please try again");
        }

        // Prevent the error from propagating up
        return;
      }
      
    } catch (error: any) {
      console.error("Emergency claim failed:", error);
      setErrorMessage("Unexpected error. Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvaxBalance = async () => {
    if (!address) return;
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(address);
      setAvaxBalance(ethers.utils.formatEther(balance));
    } catch (error) {
      console.error("Error fetching AVAX balance:", error);
    }
  };

  const handleApproval = async () => {
    try {
      setErrorMessage(null);
      setIsLoading(true);
      
      if (!auraContract || !auraFee || !address) {
        throw new Error("Contract, fee, or address not initialized");
      }

      await auraContract.call(
        "approve",
        [prizeDistributionContractAddress, auraFee]
      );

      // Wait briefly for the transaction to be processed
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Check the new allowance
      const newAllowance = await auraContract.call(
        "allowance", 
        [address, prizeDistributionContractAddress]
      );
      
      const allowanceValue = Array.isArray(newAllowance) ? newAllowance[0] : newAllowance;
      setAuraAllowance(allowanceValue);
      
      // Update needsApproval based on the new allowance
      setNeedsApproval(allowanceValue.lt(auraFee));
      
    } catch (error: any) {
      console.error("Approval error:", error);
      if (error.message?.includes("rejected") || 
          error.message?.includes("denied") ||
          error.message?.includes("cancelled")) {
        setErrorMessage("Approval cancelled.");
      } else {
        setErrorMessage("Approval failed. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Add these addresses as constants
  const TOTAL_SUPPLY = ethers.utils.parseUnits("1000000000", 18); // 1 billion with 18 decimals
  const STAKING_WALLET = "0x51697170F78136c8d143B0013Cf5B229aDe70757";
  const TREASURY_WALLET = "0x34Bf7e538BAA555053eC81704923A473ACF4b2e2";
  const DEAD_WALLET = "0x000000000000000000000000000000000000dEaD";

  // Add The City address as a constant
  const CITY_WALLET = "0xd3106799fD6D4bA51AD58A4e77a68fC10f47FF5c";

  // Add this helper function
  const formatLargeNumber = (num: number): string => {
    // First round to 2 decimal places to avoid floating point issues
    const roundedNum = Math.round(num * 100) / 100;
    
    if (roundedNum >= 1000000000) {
      return (roundedNum / 1000000000).toFixed(2) + 'B';
    } else if (roundedNum >= 1000000) {
      return (roundedNum / 1000000).toFixed(2) + 'M';
    } else if (roundedNum >= 1000) {
      return (roundedNum / 1000).toFixed(2) + 'K';
    }
    // For numbers less than 1000, round to 2 decimal places
    return roundedNum.toFixed(2);
  };

  // Update the calculateSupplies function
  const calculateSupplies = async () => {
    if (!auraContract) return;

    try {
      const [stakingBalance, treasuryBalance, burnedBalance, cityBalance] = await Promise.all([
        auraContract.call("balanceOf", [STAKING_WALLET]),
        auraContract.call("balanceOf", [TREASURY_WALLET]),
        auraContract.call("balanceOf", [DEAD_WALLET]),
        auraContract.call("balanceOf", [CITY_WALLET])
      ]);

      const excludedBalance = (Array.isArray(stakingBalance) ? stakingBalance[0] : stakingBalance)
        .add(Array.isArray(treasuryBalance) ? treasuryBalance[0] : treasuryBalance)
        .add(Array.isArray(burnedBalance) ? burnedBalance[0] : burnedBalance)
        .add(Array.isArray(cityBalance) ? cityBalance[0] : cityBalance);

      const circulating = TOTAL_SUPPLY.sub(excludedBalance);
      
      // Format with K/M/B
      const circulatingNum = Number(ethers.utils.formatUnits(Array.isArray(circulating) ? circulating[0] : circulating, 18));
      const burnedNum = Number(ethers.utils.formatUnits(Array.isArray(burnedBalance) ? burnedBalance[0] : burnedBalance, 18));
      
      setCirculatingSupply(formatLargeNumber(circulatingNum));
      setBurnedTokens(formatLargeNumber(burnedNum));
    } catch (error) {
      console.error("Error calculating supplies:", error);
      setCirculatingSupply("Error");
      setBurnedTokens("Error");
    }
  };

  // Update the claimable rewards display
  const formatClaimableRewards = () => {
    if (!claimableRewards) return "0.00";
    const rewards = Number(ethers.utils.formatUnits(claimableRewards, 18));
    return formatLargeNumber(rewards);
  };

  // Fetch fees from the contract
  useEffect(() => {
    const fetchFees = async () => {
      if (!prizeDistributionContract) return;

      try {
        const auraFeeValue = await prizeDistributionContract.call("aFee");
        const avaxFeeValue = await prizeDistributionContract.call("vFee");
        setAuraFee(auraFeeValue);
        setAvaxFee(avaxFeeValue);
      } catch (error) {
        console.error("Error fetching fees:", error);
      }
    };

    fetchFees();
  }, [prizeDistributionContract]);

  // Helper function to map prize types and determine the correct video and display name
  const getPrizeDetails = (prizeType: number, tokenAddress: string, amount: string, quantity: number = 0): { 
    videoName: string; 
    displayName: PrizeName;
  } => {
    // Convert token address and amount to lowercase for comparison
    const tokenLower = tokenAddress.toLowerCase();
    const amountBN = BigNumber.from(amount);
    
    // Debug logging
    console.log("Prize Details Input:", {
      prizeType,
      tokenAddress: tokenLower,
      amount: amountBN.toString(),
      quantity,
      isAura: tokenLower === AURA_TOKEN.toLowerCase(),
      isHeresy: tokenLower === HERESY_TOKEN.toLowerCase(),
    });
    
    switch (prizeType) {
      case 0:  // PT_NONE
        return {
          videoName: "MawsCurse",
          displayName: "Maw's Curse"
        };
      
      case 1: { // PT_ERC20
        // Debug log for ERC20 comparison
        console.log("ERC20 Token Comparison:", {
          receivedToken: tokenLower,
          auraToken: AURA_TOKEN.toLowerCase(),
          heresyToken: HERESY_TOKEN.toLowerCase(),
          amount: amountBN.toString(),
          isAura: tokenLower === AURA_TOKEN.toLowerCase(),
          isHeresy: tokenLower === HERESY_TOKEN.toLowerCase(),
        });

        if (tokenLower === AURA_TOKEN.toLowerCase()) {
          return {
            videoName: "GreedsGift",
            displayName: "Greed's Gift"
          };
        } 
        
        if (tokenLower === HERESY_TOKEN.toLowerCase()) {
          if (amountBN.eq(BigNumber.from("500000000000000000000"))) {
            return {
              videoName: "SirensCall",
              displayName: "Siren's Call"
            };
          }
          if (amountBN.eq(BigNumber.from("750000000000000000000"))) {
            return {
              videoName: "WrathsIntervention",
              displayName: "Wrath's Intervention"
            };
          }
          console.log("Heresy token with unexpected amount:", amountBN.toString());
        }

        console.log("Unknown ERC20 token or amount:", {
          token: tokenLower,
          amount: amountBN.toString()
        });
        
        // Default case should never happen if token detection is working
        return {
          videoName: "GreedsGift",
          displayName: "Greed's Gift"
        };
      }
      
      case 4:  // PT_RANDOM_ERC721
        return quantity === 2 ? {
          videoName: "SakurasEmbrace",
          displayName: "Sakura's Embrace"
        } : {
          videoName: "SpoilsOfTheCore",
          displayName: "Spoils of The Core"
        };
      
      case 5:  // PT_RANDOM_ERC1155
        return {
          videoName: "SpoilsOfTheCore",
          displayName: "Spoils of The Core"
        };
      
      case 6:  // PT_PCT_RANDOM_ERC20
        return {
          videoName: "HereticsHonour",
          displayName: "Heretic's Honour"
        };
      
      default:
        return {
          videoName: "MawsCurse",
          displayName: "Maw's Curse"
        };
    }
  };

  // Helper function to get the correct capitalized video filename
  const getVideoFileName = (baseName: string): string => {
    switch (baseName) {
      case "greedgift":
        return "GreedsGift.mp4";
      case "mawcurse":
        return "MawsCurse.mp4";
      case "spoilscore":
        return "SpoilsOfTheCore.mp4";
      case "sakuraembrace":
        return "SakurasEmbrace.mp4";
      case "sirencall":
        return "SirensCall.mp4";
      case "wrathintervention":
        return "WrathsIntervention.mp4";
      case "heretichonour":
        return "HereticsHonour.mp4";
      default:
        return "MawsCurse.mp4";
    }
  };

  return (
    <main className="main bg1">
      <div className="colbox">
        <h1 className="fa head botmar topmar">
          Enter The City
          {userDisplayName && <span>, {userDisplayName}</span>}
        </h1>
        {!isWalletConnected && (
          <ConnectWallet 
          className="ccbutton" 
          style={{ 
            fontFamily: 'Doctor Glitch',
            fontWeight: 'normal',
            fontStyle: 'oblique',
            color: 'white'
          }} 
          theme={darkTheme({ fontFamily: "Hacked, sans-serif", colors: { modalBg: "#002020", accentText: "cyan" } })}
          />
        )}
      </div>
      <div className="colbox">
      {isWalletConnected && (
        <div className="colbox bg2">
          <h2 className="fa topmar">Stake Your Analog Distortions</h2>
          <div className="section-divider"></div>
            <div className="rowboxGAP widthboost">
              
            </div>
          <div className="rowboxGAP">
            <div className="rmar">
              <div className="colbox">
                <div className="rowbox">
                  {ownedTokens?.map((nft) => (
                    <div key={nft.tokenId} className="nftbox">
                      <ThirdwebNftMedia metadata={nft.metadata} className="nftmedia" />
                      <h2 className="fa text br sidepadding">"{nft.metadata.name}"</h2>
                      <h4 className="fb text br sidepadding">Token {nft.tokenId}</h4>
                      <Web3Button
                        contractAddress={stakingContractAddress}
                        action={() => handleStake(nft.tokenId)}
                        className="stakebutton br sidemarginbm"
                      >
                        <p className="fb">Stake for Aura</p>
                      </Web3Button>
                    </div>
                  ))}
                </div>
                {hasTokenStaked && (
                  <>
                    <h3 className="fa botmar">Staked Distortions:</h3>
                    <div>
                      {stakedTokens &&
                        stakedTokens.map((stakedToken) => (
                          <NFTCard
                            tokenId={stakedToken.toNumber()}
                            key={stakedToken.toString()}
                            onUnstake={handleUnstake}
                          />
                        ))}
                    </div>
                  </>
                )}
                <div className="section-divider"></div>
              <div className="rowboxgap">
                <div className="balance-item fb order-right">
                  <b>{circulatingSupply}</b>
                  <b>Circulating Aura</b>
                </div>
                <div className="colbox center-column">
                  <div className="rowbox">
                    <div className="colbox">
                      <h3 className="fb topmar">
                        <b className="text">
                          {!claimableRewards
                            ? "Loading..."
                            : formatClaimableRewards()}
                        </b>
                        <br />
                        Aura Pending
                      </h3>
                      <Web3Button
                        className="ccbutton"
                        action={(contract) => contract.call("claimRewards")}
                        contractAddress={stakingContractAddress}
                      >
                        <h3 className="fb">Claim Rewards</h3>
                      </Web3Button>
                    </div>
                  </div>
                </div>
                <div className="balance-item fb order-left">
                  <b>{burnedTokens}</b>
                  <b>AURA Burned</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
      {isWalletConnected && (
        <div className="colbox topmar">
          <h2 className="fa">Your Tokens:</h2>
          <div className="balance-row padtop padbot">
            <div className="balance-item fb">
              <b>{auraBalance ? formatLargeNumber(Number(auraBalance.displayValue)) : "0.00"}</b>
              <b>$AURA</b>
            </div>
            
            <div className="balance-item fb">
              <b>{avaxBalance ? formatLargeNumber(Number(avaxBalance)) : "0.00"}</b>
              <b>$AVAX</b>
            </div>

            <div className="balance-item fb">
              <b>{heresyBalance ? Math.floor(Number(heresyBalance.displayValue)).toLocaleString() : "0"}</b>
              <b>$HERESY</b>
            </div>
          </div>
        </div>
      )}
      {isWalletConnected && (
        <div className="colbox bg3 botmar">
          <div className="rowbox widthboost">
            {explorationState === 'exploring' ? (
              <div className="explorebutton exploring">
                <h4 className="fb">
                  Exploring blocks<br></br>
                  {Math.min(blockConfirmations, 3)} of 3<br></br>
                  Please wait...
                </h4>
              </div>
            ) : (
              <Web3Button
                className="explorebutton"
                contractAddress={needsApproval ? auraContractAddress : prizeDistributionContractAddress}
                action={explorationState === 'ready-to-claim' 
                  ? handleExploreOrClaim 
                  : needsApproval 
                    ? handleApproval 
                    : handleExploreOrClaim
                }
              >
                <h1 className="fa bot">
                  {explorationState === 'ready-to-claim' 
                    ? "Claim Rewards" 
                    : needsApproval 
                      ? "Approve to Explore" 
                      : "Explore The City"
                  }
                </h1>
              </Web3Button>
            )}
          </div>
          <div className="section-divider"></div>
          <h2 className="fb topmar">Gluttony's Indulgence<br />500 $AURA<br />0.015 $AVAX</h2>
        </div>
      )}
      {isWalletConnected && (
        <div>
          <div className="emergency-claim-container">
            <button 
              className="emergency-claim-button"
              onClick={handleEmergencyClaim}
              disabled={isLoading}
            >
              ⚠️
            </button>
            <div className="emergency-claim-label">
              <span className="emergency-claim-arrow">←</span>
              <span className="fb">Emergency Claim</span>
            </div>
          </div>
          {errorMessage && (
            <div className="error-message fb">
              {errorMessage}
            </div>
          )}
        </div>
      )}
      </div>
      {isPlayingVideo && currentVideo && (
        <div className="video-overlay">
          <div className="video-container">
            <h3 className="fa prize-name">{currentPrizeName}</h3>
            <video
              autoPlay
              onLoadStart={() => console.log("Video loading started:", currentVideo)}
              onCanPlay={() => console.log("Video can play:", currentVideo)}
              onPlay={() => console.log("Video started playing")}
              onEnded={() => {
                console.log("Video playback ended");
                setIsPlayingVideo(false);
                setCurrentVideo(null);
                setCurrentPrizeName(null);
                setExplorationState('none');
                checkExplorationStatus();
                setNeedsApproval(true);
              }}
              onError={(e) => {
                console.error("Video playback error:", e);
                console.error("Failed video path:", currentVideo);
                // Fallback if video fails to play
                setTimeout(() => {
                  setIsPlayingVideo(false);
                  setCurrentVideo(null);
                  setCurrentPrizeName(null);
                  setExplorationState('none');
                  checkExplorationStatus();
                  setNeedsApproval(true);
                }, 3000);
              }}
              className="prize-video"
            >
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
      <div className="colbox">
      <div className="social-bar">
        <div className="social-links-container">
          <h2 className="fa">Links:</h2>
          <a 
            href="https://x.com/RIPDAOAVAX" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            X
          </a>
          <a 
            href="https://discord.com/invite/GmMvaZxRaB" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            Discord
          </a>
          <a 
            href="https://ripdao.xyz" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            RIPDAO
          </a>
          <a 
            href="https://x.com/Wrathtank_avax" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            WrathTank
          </a>
          <a 
            href="https://analogdistortions.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-link"
          >
            Analog Distortions
          </a>
          <a 
            href="https://bullet.exchange/collections/66fee59823bad0b9b3584bd2" 
            className="social-link"
          >
            BUY HERESY
          </a>
          <a 
            href="/roleclaim" 
            className="social-link"
          >
            Role Claim
          </a>
        </div>
        <span className="developer-credit fb">
          Developed with ♥by <a href="https://x.com/SnapsNoCaps" target="_blank" rel="noopener noreferrer">SnapsNoCaps</a>
        </span>
      </div>
      </div>
      
    </main>
  );
};

export default App;

