import React, { useEffect, useState } from 'react';
import { 
  ConnectWallet,
  Web3Button,
  useContract,
  useAddress,
} from "@thirdweb-dev/react";
import { darkTheme } from "@thirdweb-dev/react";
import "../styles/Home.css";
import { Link } from 'react-router-dom';

const RoleClaim: React.FC = () => {
  const address = useAddress();
  const [displayName, setDisplayName] = useState("");
  const [discordUsername, setDiscordUsername] = useState("");
  const [currentDisplayName, setCurrentDisplayName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Initialize contract
  const { contract: roleClaimContract } = useContract(
    "0x5463143F0E79DBaA602AebA78d6c31A433d6F96F",
    "custom"
  );

  // Fetch current display name when wallet connects
  useEffect(() => {
    const fetchDisplayName = async () => {
      if (!address || !roleClaimContract) return;
      
      try {
        const name = await roleClaimContract.call("readDisplayName", [address]);
        if (name && name !== "") {
          setCurrentDisplayName(name);
        }
      } catch (error) {
        console.error("Error fetching display name:", error);
      }
    };

    fetchDisplayName();
  }, [address, roleClaimContract]);

  const handleSubmit = async () => {
    if (!displayName || !discordUsername) {
      setErrorMessage("Please fill in both fields");
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      await roleClaimContract?.call(
        "registerUser",
        [displayName, discordUsername]
      );
      
      setCurrentDisplayName(displayName);
      setDisplayName("");
      setDiscordUsername("");
      
    } catch (error: any) {
      console.error("Registration error:", error);
      
      if (error.message?.includes("user rejected")) {
        setErrorMessage("Transaction was cancelled");
      } else if (error.message?.includes("name already taken")) {
        setErrorMessage("This display name is already taken");
      } else if (error.message?.includes("discord already registered")) {
        setErrorMessage("This Discord username is already registered");
      } else {
        setErrorMessage("Registration failed. Please try again");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="main bg1">
      <div className="colbox">
        
        
        <h1 className="fa head botmar topmar">Role Claim</h1>
        <h4 className="fb botmar">enter a display name and your discord username<br></br> to claim token-gated discord roles</h4>
        
        {!address ? (
          <ConnectWallet 
            className="ccbutton" 
            style={{ 
              fontFamily: 'Doctor Glitch',
              fontWeight: 'normal',
              fontStyle: 'oblique',
              color: 'white'
            }} 
            theme={darkTheme({ 
              fontFamily: "Hacked, sans-serif", 
              colors: { 
                modalBg: "#002020", 
                accentText: "cyan" 
              } 
            })}
          />
        ) : (
          <div className="colbox bg2">
            {currentDisplayName && (
              <h2 className="fb topmar">
                Welcome back, {currentDisplayName}!
              </h2>
            )}
            <div className="input-container">
              <input
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder="Display Name"
                className="role-input"
              />
              <input
                type="text"
                value={discordUsername}
                onChange={(e) => setDiscordUsername(e.target.value)}
                placeholder="Discord Username"
                className="role-input"
              />
              {errorMessage && (
                <div className="error-message fb">
                  {errorMessage}
                </div>
              )}
              <Web3Button
                contractAddress="0x5463143F0E79DBaA602AebA78d6c31A433d6F96F"
                action={handleSubmit}
                className="explorebutton"
                isDisabled={isLoading}
              >
                <span className="fb">
                  {isLoading ? "Processing..." : (currentDisplayName ? "Update" : "Register")}
                </span>
              </Web3Button>
              <h4 className="fb botmar">Roles may take some time to update.</h4>
            </div>
            <Link to="/" className="fb back-link">← Back to The City</Link>
          </div>
        )}
      </div>
    </main>
  );
};

export default RoleClaim; 